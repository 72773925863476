import 'amfe-flexible'


import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import router from '@/router'

import App from './App'

import '@/style/theme.less'

Vue.use(VueClipboard)

new Vue({
  router,
  el: '#app',
  render: h => h(App)
})
